import React, { useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  TextField,
  Button,
  Container,
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/Reducers/GlobalReducer/globalSlice";
import { useSnackbarContext } from "../../component/SnackbarContext";
import { endPoints } from "../../constant/Environment";
import { addData } from "../../Utility/API";

// Validation schema
const validationSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  // lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: yup
    .string()
    .matches(/^\d+$/, "Only numbers are allowed.")
    .min(10, "Phone number should be of minimum 10 digits.")
    .max(10, "Phone number should be of maximum 10 digits.")
    .required("Phone is required."),
  description: yup.string().required("Message is required"),
  workingWithRealtor: yup.string().required("This field is required"),
  isRealtor: yup.string().required("This field is required"),
});

const ContactUsWestWood = (props) => {
  const dispatch = useDispatch();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarContext();
  // const contactRef = useRef(null);

  // Formik setup
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      description: "",
      workingWithRealtor: "",
      isRealtor: "",
      homeLooking:""
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let url = `${endPoints.api.EMAIL_SUPPORT_DOON}`;
      console.log('values',values)
      let data = {
        firstName: values.firstName,
        // lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        description: values.description,
        workingWithRealtor: values.workingWithRealtor,
        isRealtor: values.isRealtor,
        website: "WestWood",
      };
      dispatch(setLoading(true));
      try {
        const response = await addData(url, data);
        dispatch(setLoading(false));
        if (response.data.status === "success") {
          showSuccessSnackbar("Thank you for contacting us.");
          resetForm();
        } else {
          showErrorSnackbar(response.data.message);
        }
      } catch (error) {
        dispatch(setLoading(false));
        showErrorSnackbar("An error occurred. Please try again.");
      }
    },
  });

  return (
    <section className="col-md-11" id={props.id} ref={props.contactRef}>
      <Container maxWidth="md" className="custom_width cofee2_form">
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          className="heading_w mb-5  mt-4 c_heading"
        >
          Get in touch
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Full Name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Email ID"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Contact Number"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                className="text_field"
                placeholder="Your Message"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Typography variant="h4">
                  Are you currently working with a Realtor?
                </Typography>
                <RadioGroup
                  grid
                  name="workingWithRealtor"
                  value={formik.values.workingWithRealtor}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "18px",
                        fontWeight: "400",
                      },
                    }}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label="No"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "18px",
                        fontWeight: "400",
                      },
                    }}
                  />
                </RadioGroup>
                {formik.touched.workingWithRealtor &&
                  formik.errors.workingWithRealtor && (
                    <Typography color="error">
                      {formik.errors.workingWithRealtor}
                    </Typography>
                  )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Typography variant="h4">Are you a Realtor?</Typography>
                <RadioGroup
                  grid
                  name="isRealtor"
                  value={formik.values.isRealtor}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "18px",
                        fontWeight: "400",
                      },
                    }}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label="No"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "18px",
                        fontWeight: "400",
                      },
                    }}
                  />
                </RadioGroup>
                {formik.touched.isRealtor && formik.errors.isRealtor && (
                  <Typography color="error">
                    {formik.errors.isRealtor}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Typography variant="h3">
                  Type of Home you’re looking for
                </Typography>
                <RadioGroup
                  row
                  name="homeLooking"

                  value={formik.values.homeLooking}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="option1"
                    control={<Radio />}
                    label="20’ Two Story Towns"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "18px",
                        fontWeight: "400",
                      },
                    }}
                  />
                  <FormControlLabel
                    value="option2"
                    control={<Radio />}
                    label="30’ Detached"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "18px",
                        fontWeight: "400",
                      },
                    }}
                  />
                  <FormControlLabel
                    value="option3"
                    control={<Radio />}
                    label="36’ Detached"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "18px",
                        fontWeight: "400",
                      },
                    }}
                  />
                </RadioGroup>
                {/* {formik.touched.workingWithRealtor &&
                  formik.errors.workingWithRealtor && (
                    <Typography color="error">
                      {formik.errors.workingWithRealtor}
                    </Typography>
                  )} */}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                className="submit_button"
                variant="contained"
                fullWidth
                type="submit"
                style={{
                  marginBottom: "20px",
                  padding: "0.8rem",
                  background: "var(--cofee)",
                  fontSize: "18px",
                  fontWeight: "400",
                  bordeRadius: "8px",
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </section>
  );
};

export default ContactUsWestWood;
