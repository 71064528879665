import React, { useState } from "react";
import { useEffect, useRef } from "react";
import WestHeader from "./WestHeader";
import logo2 from "./../../images/westwood/westwoood-logo.svg";
import banner from "./../../images/westwood/banner.webp";
import welcomeimg from "./../../images/westwood/welcome-img.svg";
import buttericon from "./../../images/westwood/butter-icon.svg";
import Crescentlogo from "./../../images/westwood/Crescent-Homes.svg";
import img1 from "./../../images/westwood/img1.webp";
import img2 from "./../../images/westwood/img2.webp";
import icon1 from "./../../images/westwood/bed.svg";
import icon2 from "./../../images/westwood/bath.svg";
import icon3 from "./../../images/westwood/car.svg";
import map from "./../../images/westwood/map1.webp";
import map2 from "./../../images/westwood/map2.webp";
import icon01 from "./../../images/westwood/icon01.svg";
import icon02 from "./../../images/westwood/icon02.svg";
import icon03 from "./../../images/westwood/icon03.svg";
import icon04 from "./../../images/westwood/icon04.svg";
import icon05 from "./../../images/westwood/icon05.svg";
import icon06 from "./../../images/westwood/icon06.svg";
import icon07 from "./../../images/westwood/icon07.svg";
import img01 from "./../../images/westwood/image1.webp";
import img02 from "./../../images/westwood/image2.webp";
import img03 from "./../../images/westwood/image3.webp";
import img04 from "./../../images/westwood/image4.webp";
import vedio from "./../../images/westwood/vedio.webp";
import img5 from "./../../images/westwood/image5.webp";
import img6 from "./../../images/westwood/image6.webp";
import img7 from "./../../images/westwood/list.svg";
import img8 from "./../../images/westwood/image7.webp";
import img9 from "./../../images/westwood/naksha.webp";
import img10 from "./../../images/westwood/img3.webp";


import "./westwood.css";
import { Link } from "react-router-dom";
import ContactNew from "./ContactNew";
import ContactUsWestWood from "./ContactUsWestWood";
import WestFooter from "./WestFooter";

const WestPage = () => {
    const [menu, setMenu] = useState('community')
    const communityRef = useRef(null)
    const homesRef = useRef(null);
    const SiteMapRef = useRef(null);
    const neighbourRef = useRef(null);
    const communityBrochureRef = useRef(null);
    const contactRef = useRef(null);
    const handleScroll = (ref, name) => {

        const offset = 100;
        const sectionPosition =
            ref?.current?.getBoundingClientRect()?.top + window?.pageYOffset;

        window.scrollTo({
            top: sectionPosition - offset,
            behavior: "smooth",
        });
        setMenu(name)
    };

    return (
        <main id="woodweb" className="inner_wood">
            <WestHeader menuName={menu} communityRef={communityRef} homesRef={homesRef} SiteMapRef={SiteMapRef} neighbourRef={neighbourRef} communityBrochureRef={communityBrochureRef} contactRef={contactRef} handlePage={handleScroll} />

            <section className="sec-padd bg-light01 inner_first">
                <div className="container">
                    <div className="row g-md-5 g-4">
                        <div className="col-md-5">
                            <h3 class="heading3_w pb-2 most-font">36’ Detached</h3>
                            <h6>Available in <b>2100 Sq ft</b> & <b>2359 Sq ft</b></h6>
                            <div className="card_img">
                                <ul>
                                    <li>
                                        <img src={icon1} />
                                        <span>3</span>
                                    </li>
                                    <li>
                                        <img src={icon2} />
                                        <span>1.5 + 1</span>
                                    </li>
                                    <li>
                                        <img src={icon3} />
                                        <span>1 Car Garage</span>
                                    </li>
                                </ul>

                                <h5>Title</h5>
                                <p>
                                    Jäment dolung chefsnappning plaplaval kånar prepön. Didöbel apåskap. Mibel diagraf hyposekade, kyktigt, som ösade, när nede.
                                    Tet hypertotal kism, i dekalyren, nikängar med askänka. Vunde bior. Epipp kroregon i ren pösk. </p>

                                <h5>Main Floor</h5>
                                <p>
                                    Nir ande, nakar al. Urat neskap inklusive antegöck för att misopod, tevårade våsat. Vafasam kontrar semiv då hår.
                                    Nipanongar sehet lask misk. Lårskav. Rearen ruliga, tesamma.
                                    Cookie kron blattesvenska, asamma. Fipp nipäst i bes. Teradade nirade kiktiga i banar.</p>

                                <h5>Second Floor</h5>
                                <p>
                                    Prebel pemibel, ten. Anade vaning, dode. Anaskap vikahet: faterat inklusive pyrat åra. Du kan vara drabbad. </p>

                                <h5>ENERGY STAR® CERTIFIED</h5>
                                <p>
                                    Kopimism bet. Dössa bektigt kontraska biliga. Vikibel debagt teniktig om jenar i göra en labrador alltså vang. Fiv våkil har prena. Bäse homolang, decilig än monodalögisk. Våkönade mil.
                                    Pseudorar krogav huruvida medelaktiv kövis plastis astrodat.</p>

                                <div className="mt-5 pt-5 mb-5">

                                    <Link href="#" className="btn_dark">know more</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <img src={img10} />
                        </div>

                    </div>
                </div>
            </section>

            <section className="sec-padd bg-light03">
                <div className="container">

                    <div className="row g-md-5 g-4 justify-content-between">
                        <div className="col-md-3">
                            <h3 class="heading3_w pb-5 most-font">Floor Plan</h3>
                            <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button class="nav-link active" id="v-pills-first-tab" data-bs-toggle="pill" data-bs-target="#v-pills-first" type="button" role="tab" aria-controls="v-pills-first" aria-selected="true">main floor</button>
                                <button class="nav-link" id="v-pills-second-tab" data-bs-toggle="pill" data-bs-target="#v-pills-second" type="button" role="tab" aria-controls="v-pills-second" aria-selected="false">Second floor</button>

                                <button class="nav-link" id="v-pills-third-tab" data-bs-toggle="pill" data-bs-target="#v-pills-third" type="button" role="tab" aria-controls="v-pills-third" aria-selected="false">basement</button>
                            </div>
                            {/*  */}
                            <div class="tab-content" id="v-pills-tabContent">
                                <div class="tab-pane fade show active" id="v-pills-first" role="tabpanel" aria-labelledby="v-pills-first-tab" tabindex="0">
                                    <h5>Main Floor</h5>
                                    <p>Nir ande, nakar al. Urat neskap inklusive antegöck för att misopod, tevårade våsat. Vafasam kontrar semiv då hår. Nipanongar sehet lask misk. Lårskav. Rearen ruliga, tesamma.</p>
                                    <p>Cookie kron blattesvenska, asamma. Fipp nipäst i bes. Teradade nirade kiktiga i banar.</p>
                                </div>
                                <div class="tab-pane fade" id="v-pills-second" role="tabpanel" aria-labelledby="v-pills-second-tab" tabindex="0">
                                    <h5>Second Floor</h5>
                                    <p>Nir ande, nakar al. Urat neskap inklusive antegöck för att misopod, tevårade våsat. Vafasam kontrar semiv då hår. Nipanongar sehet lask misk. Lårskav. Rearen ruliga, tesamma.</p>
                                    <p>Cookie kron blattesvenska, asamma. Fipp nipäst i bes. Teradade nirade kiktiga i banar.</p>
                                </div>

                                <div class="tab-pane fade" id="v-pills-third" role="tabpanel" aria-labelledby="v-pills-third-tab" tabindex="0">
                                    <h5>Basement</h5>
                                    <p>Nir ande, nakar al. Urat neskap inklusive antegöck för att misopod, tevårade våsat. Vafasam kontrar semiv då hår. Nipanongar sehet lask misk. Lårskav. Rearen ruliga, tesamma.</p>
                                </div>
                            </div>
                            {/*  */}
                        </div>
                        <div className="col-md-8">
                            <img src={img9} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="sec-padd map_home bg-light01" ref={SiteMapRef}>
                <div className="container">
                    <div className="sec-padd-b">
                        <div className="col-md-12">
                            <ContactNew />
                        </div>
                    </div>
                    <div className="heading_img">
                        <h2 className="heading_w d-font">Site Map</h2>
                        <img src={buttericon} />

                    </div>
                    <div className="container">
                        <img className="pb-5 w-100" src={map} />
                    </div>
                    <div className="row g-md-5 g-4 align-items-center">
                        <div className="col-md-6 ">
                            <div className="flex_logo justify-content-center">
                                <img src={Crescentlogo} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h4 className="most-font fw-semibold">About Site Map</h4>
                            <p className="most-font">In the heart of the Mariposa neighborhood, you’ll find yourself surrounded by beauty as striking as its namesake. Strolling the trails, you might even be fortunate enough to spot the elusive Baltimore checkerspot butterfly, a rare and endangered treasure fluttering through this serene haven.</p>
                        </div>

                    </div>

                </div>
            </section>




            <section className="sec-padd community_a bg-light02">
                <div className="container">
                    <div className="heading_img">
                        <h2 className="heading_w d-font">Community Amenities</h2>
                        <img src={buttericon} />
                    </div>

                    <div className="row g-md-5 g-4 justify-content-center align-items-center">
                        <div className="col-md-4">
                            <img src={vedio} />

                        </div>
                        <div className=" offset-md-1 col-md-6">
                            <h3>Bismark Pak</h3>
                            <p>The park features a splash pad, spray wall, junior and senior playgrounds, junior and intermediate skate parks, a pavilion with on-site washrooms and change rooms, a tennis court, a pickleball court, a three-on-three basketball court, fitness stations, table games areas, a disc golf course, an amphitheatre and trails connecting to schools and other trails.</p>
                        </div>

                    </div>


                </div>
            </section>

            <section className="sec-padd Amenities_sec bg-light01" >
                <div className="container">
                    <div className="heading_img">
                        <h2 className="heading_w d-font">Amenities Map</h2>
                        <img src={buttericon} />
                    </div>

                    <div className="row g-md-5 g-4">
                        <div className="col-md-8">
                            <img src={img5} />
                        </div>
                        <div className="col-md-4">
                            <img src={img6} />
                            <div className="img_div">
                                <img src={img7} />
                            </div>
                        </div>
                    </div>
                    <div className="sec-padd-t">
                        <div className="row g-md-5 g-4">
                            <div className="col-md-6">
                                <img src={img8} />

                            </div>
                            <div className="col-md-6" ref={contactRef}>
                                <ContactUsWestWood />
                            </div>

                        </div>

                    </div>

                </div>
            </section>

            <WestFooter />

        </main>
    );
};

export default WestPage;
