import React, { useState, useEffect, useRef } from "react";
import logo2 from "./../../images/westwood/westwoood-logo.svg";
import logo from "./../../images/westwood/Crescent-footer.svg";
import { Link } from 'react-router-dom';
function WestFooter() {

  return (
    <footer className='WestFooter'>
        <div className='container'>
        <div className='flex_img'>
        <img src={logo} alt="logo"/>
        <img src={logo2} alt="logo" />
        </div>
        <ul className='footer_nav'>
           <li><Link href=""


           >Community</Link></li>
           <li><Link href="#">Our homes</Link></li>
           <li><Link href="#">site Map</Link></li>
           <li><Link href="#">Neighbourhood</Link></li>
           <li><Link href="#">Community Brochure</Link></li>
           <li><Link href="#">Contact</Link></li>


        </ul>

        </div>
    </footer>
  )
}

export default WestFooter